// *
// * Erdmann & Freunde - Nutshell
// * BASE Contao Framework
// * erdmann-freunde.de/nutshell/
// *
// * Lizenziert unter MIT OPEN SOURCE 
// *

//
// COMPONENTS/EVENTS
// --------------------------------------------------

// VARIABLES
$event-border:  $base-border !default;

// USED FROM _variables.scss
// $base-spacing-unit
// $base-spacing-unit--sm
// $headings__font-weight;
// $headings__font-family;
// $heading__size-4
// $heading__l-h--4
// $heading__m-t--4
// $heading__m-b--4

// --------------------------------------------------

// event list
// [1] call every headline element (h1-h6)
// [2] headline for FE-Module
.events-list {
  margin-bottom: $base-spacing-unit;
  
  > { 
    #{headings()} { // [1]
      margin-bottom: 0;
      
      &:after {
        border-top: $event-border;
        content: "";
        display: block; 
      }
    }
  }
  
  .image_container {
    margin-top: $base-spacing-unit;
    margin-bottom: $base-spacing-unit;
  }
    
  .float_left {
    margin-right: $base-spacing-unit;
  }
  
  .float_right {
    margin-left: $base-spacing-unit;
  } 
  
  .event {
    @extend %clearfix;
    
    > { 
      #{headings()} { // [1] 
        margin-top: $base-spacing-unit;
      }
    }
    
    &:after {
      border-bottom: $base-border;
      margin-top: $base-spacing-unit;
      content: "";
      display: block;
    }
  }
  
  .layout_upcoming {
    time,
    a {
      padding-top: $base-spacing-unit;
      display: inline-block;
    }
  }
  
  .header {
    margin-top: $base-spacing-unit;
  }
}


// [2] add border-top to the first element after headline
.events-menu {
  @include navigation();
  
  > {  
    #{headings()} { // [1]
      
      &:after {
        border-top: $news-border;
        content: "";
        display: block; 
      }
    }
  }
  
  .year {
    margin-bottom: $base-spacing-unit--sm;
    
     > a,
     > .active {
      color: inherit;
      font-weight: $headings__font-weight;
      font-family: $headings__font-family;
    }
  }
  
  .level_2 li {
    margin-bottom: $base-spacing-unit--xs;
  }
}
